.speedometer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.speedometer svg {
  overflow: visible;
}

.speedometer text {
  font-family: Arial, sans-serif;
}

.speedometer path {
  transition: transform 0.3s;
}

.fear-greed-index {
  width: 100%;
  max-width: 400px;
  margin: 15px auto 0px;
}

.index-bar {
  position: relative;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: visible;
  margin: 10px 0;
}

.index-segments {
  display: flex;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.segment {
  flex: 1;
}

.index-pointer {
  position: absolute;
  top: -8px;
  width: 8px;
  height: calc(100% + 16px);
  background-color: #000000eb;
  transform: translateX(-50%);
  border-radius: 4px;
  z-index: 2;
}

.index-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 12px;
  color: #ffffff;
}

.index-value {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.1em;
}
