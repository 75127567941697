body {
  background-color: #232323;
  color: #e6e6e6;
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
}

main {
  padding: 20px;
  padding-bottom: 0px;
  color: #d9d9d9;
}

.App {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

@media (max-width: 479px) {
  main {
    padding: 0px;
  }
  header {
    padding-inline: 10px;
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .App {
    max-width: 100%;
  }
}

@media (min-width: 930px) {
  .App {
    max-width: 95%;
  }
}

@media (min-width: 1024px) {
  .App {
    max-width: 90%;
  }
}

@media (min-width: 1440px) {
  .App {
    max-width: 65%;
  }
}

@media (min-width: 2024px) {
  .App {
    max-width: 60%;
  }
}
