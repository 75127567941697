.title {
  font-size: 2.2em;
  color: #ffffff;
  font-weight: 700;
  margin-top: 0px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.content {
  padding: 20px;
}

@media (max-width: 480px) {
  .title {
    font-size: 1.6em;
    padding-bottom: 20px;
    padding-inline: 5px;
  }
  .content {
    padding: 0px;
  }
}
