.card {
  background-color: #383838;
  color: #fff;
  padding: 25px;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.nameContainer {
  max-width: 100%;
}

.card h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 2em;
  color: #fff;
}

.card h3 {
  margin-top: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff;
  font-size: 1.5em;
  font-size: 1.5em;
}

.card h4 {
  margin-top: 0px;
  margin-bottom: 8px;
  color: #fff;
  font-size: 1.1em;
}

.stockContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}

.predictionWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #232323;
  padding: 17px;
  border-radius: 8px;
  width: 35%;
  min-width: 100px;
  height: auto;
  flex-grow: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.priceSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.priceBlock {
  background-color: #383838;
  padding: 15px;
  padding-inline: 10px;
  border-radius: 8px;
  text-align: center;
  flex-grow: 1;
  margin: 7px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.priceBlock:first-child {
  margin-top: 0;
}

.priceBlock:last-child {
  margin-bottom: 0;
}

.stockImage {
  width: 65%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  margin-left: 20px;
}

.financialTableWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.financialTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d2d2d3;
}

.financialTable th,
.financialTable td {
  padding: 8px 15px;
  border-bottom: 1px solid #d2d2d3;
  font-size: 1em;
  text-align: left;
}

.financialTable tr:nth-child(even) {
  background-color: #232323;
}
.financialTable td:nth-child(even) {
  text-align: right;
}

.financialScoreWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
}

.currentPrice,
.predictedPrice,
.percentageChange {
  font-size: 1.2em !important;
  margin: 0;
  font-weight: bold;
  width: 100%;
}

.currentPrice,
.predictedPrice,
.percentageChange {
  font-size: 1.2em !important;
  margin: 0;
  font-weight: bold;
}

.positiveChange {
  color: #01c864;
}

.negativeChange {
  color: #d9534f;
}

.arrowIcon {
  font-weight: normal;
}

.stockLink {
  color: #fff;
  text-decoration: none;
}

.stockLink:hover {
  text-decoration: underline;
}

.extremeGreedText {
  margin-top: 10px;
  font-size: 1.8em;
  color: #fff;
  text-align: center;
}

@media (min-width: 480px) {
  .predictionWrapper {
    width: 100%;
  }

  .stockImage {
    width: 100%;
    max-height: 500px;
    min-height: 400px;
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (min-width: 820px) {
  .predictionWrapper {
    width: 25%;
  }

  .stockImage {
    width: 65%;
    margin-left: 20px;
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .predictionWrapper {
    width: 25%;
  }

  .stockImage {
    width: 60%;
  }
}

@media (min-width: 1440px) {
  .predictionWrapper {
    width: 20%;
  }

  .stockImage {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .financialTableWrapper {
    width: 100%;
  }

  .cardHeader h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .financialTable th,
  .financialTable td {
    font-size: 0.9em;
    padding: 8px 10px;
  }

  .cardHeader {
    justify-content: center;
  }

  .stockImage {
    padding-top: 10px;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 10px;
    margin-inline: 10px;
  }

  .card h2 {
    margin-top: 10px;
    font-size: 1.5em;
  }

  .card h4 {
    margin-top: 0;
  }

  .priceBlock {
    padding: 10px;
    margin: 5px 0;
  }

  .financialTable th,
  .financialTable td {
    font-size: 0.8em;
    padding: 6px 8px;
  }

  .financialTable td {
    word-wrap: break-word;
  }

  .predictionWrapper {
    padding: 10px;
    margin-bottom: 10px;
  }

  .stockImage {
    width: 100%;
    height: 370px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .financialTable th,
  .financialTable td {
    padding: 6px;
    font-size: 0.8em;
  }
}
