.date {
  font-size: 1.5em;
  font-weight: 400;
  margin: 0;
  color: #aaa;
}

@media (max-width: 768px) {
  .date {
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .date {
    font-size: 0.8em;
  }
}
