.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
  border-bottom: 3px solid #ffffff;
  flex-wrap: wrap;
  padding: 20px;
  padding-bottom: 20px;
}

.logo {
  width: 30%;
  max-width: 400px;
  min-width: 100px;
}

.titleContainer {
  text-align: right;
  flex: 1;
}

.title {
  font-size: 2.4em;
  font-weight: 700;
  margin: 0;
  color: #ffffff;
  line-height: 1.1;
}

@media (max-width: 1024px) {
  .title {
    font-size: 1.8em;
  }
  .logo {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 35%;
    text-align: center;
    margin-bottom: 0px;
  }
  .titleContainer {
    width: 50%;
  }
  .title {
    font-size: 1.1em;
  }
}
