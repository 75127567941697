.star-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
}

.star-rating .title {
  margin-top: 10px;
  margin-bottom: auto;
  font-size: 1em;
  color: #fff;
  text-align: center;
}

.stars {
  display: flex;
  flex-direction: row;
}

.star {
  font-size: 2em;
  color: #ffd700;
  margin: 0 5px;
}

.star.empty {
  color: #555;
}

.star.partial {
  color: #ffd700;
  background: linear-gradient(
    to right,
    #ffd700 var(--partial-width),
    #555 var(--partial-width)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.star.partial::before {
  content: "★";
  color: #555;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.star.full {
  color: #ffd700;
}

@media (max-width: 480px) {
  .star {
    font-size: 2em;
    margin: 0 2px;
  }
}
