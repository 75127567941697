.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  border-top: 3px solid #ffffff;
  flex-wrap: wrap;
}

.footerTitleContainer {
  text-align: center;
  flex: 1;
}

.contactInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  color: #ffffff;
  line-height: 1.1;
}

.link {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.link:hover {
  text-decoration: underline;
}

.icon {
  margin-inline: 7px;
  vertical-align: middle;
  font-size: 1.5em;
}

.emailIcon {
  margin-right: 7px;
  font-size: 1.4em;
}

.separator {
  margin: 0 20px;
}

@media (max-width: 1024px) {
  .contactInfo {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    margin-inline: 10px;
    padding: 20px 0;
  }

  .contactInfo {
    text-align: center;
    font-size: 0.8em;
  }

  .separator {
    margin: 0 5px;
  }
}
