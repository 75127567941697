.card {
  background-color: #383838;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 35px;
  text-align: center;
  margin-inline: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card h2 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 2em;
}

.card p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1em;
  text-align: justify;
  padding-inline: 10px;
  line-height: 1.3;
}

.wallets {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;
}

.wallet {
  margin: 10px 0;
  background-color: #232323;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.wallet h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.walletTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.icon {
  font-size: 1.5em;
}

.walletAddress {
  font-family: "Courier New", Courier, monospace;
  word-break: break-all;
  background-color: #383838;
  padding: 10px;
  border-radius: 5px;
  color: #00ff99;
  margin-bottom: 5px;
  font-size: 1em !important;
  text-align: center !important;
}

@media (max-width: 480px) {
  .card {
    padding: 10px;
    margin-inline: 10px;
  }

  .card h2 {
    font-size: 1.5em;
  }

  .card p {
    font-size: 0.8em;
    padding-inline: 2px;
  }

  .wallets {
    flex-direction: column;
    gap: 5px;
  }

  .wallet {
    width: 100%;
    margin: 5px 0;
    padding: 10px;
    box-sizing: border-box;
  }

  .walletAddress {
    font-size: 0.8em;
    padding: 6px;
  }
}
